import amplitude from 'amplitude-js';
import { eventProperties } from './amplitudeConfig';
import cloneDeep from 'lodash.clonedeep';

export const initAmplitudeOnClick = () => {
    const elements = document.querySelectorAll('[data-amplitude-click]');

    elements.forEach((element) => {
        element.addEventListener('click', function () {
            const event = this.dataset.amplitudeClick;
            const amplitudeProperties = cloneDeep(eventProperties);

            if (this.hasAttribute('data-page-location')) {
                amplitudeProperties.page_location = this.getAttribute('data-page-location');
            }

            amplitude.getInstance().logEvent(event, amplitudeProperties);
        });
    });
};
