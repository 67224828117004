import Swiper from 'swiper/bundle';

export const initTestimonialsSlider = () => {
    const sliderTestimonials = new Swiper('[data-testimonials-slider]', {
        loop: true,
        centeredSlides: true,
        spaceBetween: 10,
        breakpoints: {
            0: {
                slidesPerView: 1.5,
            },
            600: {
                slidesPerView: 2.5,
            },
        },
    });
};
