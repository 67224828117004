import amplitude from 'amplitude-js';
import { eventProperties } from './amplitudeConfig';
import cloneDeep from 'lodash.clonedeep';

export const initAmplitudeOnLoad = () => {
    const amplitudeProperties = cloneDeep(eventProperties);
    const amplitudePage = document.querySelector('[data-amplitude-load]');
    const amplitudePricePage = document.querySelector('[data-amplitude-id-page]');

    if (amplitudePage) {
        const value = amplitudePage.dataset.amplitudeLoad;
        let event = 'load_page';

        if (value === 'shop_500_error') {
            event = 'shop_500_error';
        } else if (value === 'shop_404_error') {
            event = 'shop_404_error';
        }

        amplitude.getInstance().logEvent(event, amplitudeProperties);
    }

    // For Prices page, set devise ID
    if (amplitudePricePage) {
        const ampDeviceIdInterval = setInterval(() => {
            if (amplitude.getInstance().options && amplitude.getInstance().options.deviceId) {
                const deviceId = amplitude.getInstance().options.deviceId;
                const deviseIdInputs = document.querySelectorAll('[data-amplitude-id-device]');

                [...deviseIdInputs].forEach((input) => {
                    input.value = deviceId;
                });

                clearInterval(ampDeviceIdInterval);
            }
        }, 300);
    }
};
