import amplitude from 'amplitude-js';
import { eventProperties } from './amplitudeConfig';
import cloneDeep from 'lodash.clonedeep';

export const initAmplitudeOnSubmit = () => {
    const forms = document.querySelectorAll('[data-amplitude-form-submit]');

    forms.forEach((form) => {
        form.addEventListener('submit', function () {
            const event = this.dataset.amplitudeFormSubmit;
            const amplitudeProperties = cloneDeep(eventProperties);
            const errors = this.querySelectorAll('.sign__form-error:not(.hide)');
            const inputEmail = this.querySelector('[data-create-account-email]');

            if (errors.length) {
                this.dataset.validationErrorCount = `${+this.dataset.validationErrorCount + 1}`;
                amplitudeProperties.validation_error = '';
            }

            if (this.hasAttribute('data-page-location')) {
                amplitudeProperties.page_location = this.getAttribute('data-page-location');
            }

            errors.forEach((error) => {
                amplitudeProperties.validation_error += `${error.textContent.trim()}, `;
                amplitudeProperties.validation_error_count = this.dataset.validationErrorCount;
            });

            if (inputEmail) {
                amplitudeProperties.user_email = inputEmail.value.trim();
            }

            amplitude.getInstance().logEvent(event, amplitudeProperties);
        });
    });
};
