export const initParallax = () => {
    const MAX_ROT = 10;
    const ANIM_SPEED = 0.075;

    let frame, card, img, mouse, userPos, hover, padding, center;

    function lerp(n1, n2, speed) {
        return (1 - speed) * n1 + speed * n2;
    }

    Array.prototype.lerp = function (target, speed) {
        this.forEach((n, i) => (this[i] = lerp(n, target[i], speed)));
    };

    function resize() {
        if (!card) return;
        let rect = card.getBoundingClientRect();
        center = [0.5 * card.clientWidth + rect.left, 0.5 * card.clientHeight + rect.top];
        userPos = [center[0], center[1]];
    }

    function loop() {
        let xPosNorm, yPosNorm, mouseDistNorm, theta, xRot, yRot, imgX, imgY;

        userPos.lerp(hover ? mouse : center, ANIM_SPEED);

        xPosNorm = (center[0] - userPos[0]) / center[0];
        yPosNorm = (center[1] - userPos[1]) / center[1];

        imgX = xPosNorm * padding[0];
        imgY = yPosNorm * padding[1];

        mouseDistNorm = Math.sqrt(xPosNorm * xPosNorm + yPosNorm * yPosNorm);
        theta = Math.atan2(userPos[1] - center[1], userPos[0] - center[0]);

        xRot = Math.sin(-theta) * MAX_ROT * mouseDistNorm; //card x-axis rotation based on mouse y-axis position
        yRot = Math.cos(theta) * MAX_ROT * mouseDistNorm;

        card.style.transform = `rotateX(${xRot}deg) rotateY(${yRot}deg)`;
        // img.style.transform = `translateX(${imgX}px) translateY(${imgY}px)`;
        window.requestAnimationFrame(loop);
    }

    function init() {
        frame = document.querySelector('[data-parallax-block]');
        card = document.querySelector('[data-parallax-card]');
        img = document.querySelector('[data-parallax-img]');
        if (!img) return;
        mouse = [0, 0];
        hover = false;
        padding = [0.5 * (img.clientWidth - card.clientWidth), 0.5 * (img.clientHeight - card.clientHeight)];

        resize();

        window.addEventListener('mousemove', (e) => {
            mouse[0] = e.clientX;
            mouse[1] = e.clientY;
            hover = true;
        });

        loop();
    }
    init();
    window.addEventListener('resize', resize, false);
};
